import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import { getPatient, getPatientResults } from "~/src/api-client/patients";
import ButtonLink from "~/src/components/common/inputs/buttons/ButtonLink";
import DashboardLayout from "~/src/components/DashboardLayout";
import CollapsableResults from "~/src/components/specifics/CollapsibleResults";
import { useContextUpdate, useContextValue } from "~/src/context";
import ArrowLeftIcon from "~/src/icons/ArrowLeftIcon";
import { User } from "~/src/types/models";
import { AppointmentsResult } from "~/src/types/results";

type PatientResultsProps = {
  id: string;
};

const PatientResults = ({ id }: PatientResultsProps) => {
  const patientId: string = id;
  const [patient, setPatient] = useState<User | undefined>(undefined);
  const [appointmentResults, setAppointmentResults] = useState<AppointmentsResult[]>([]);
  const { session, rehydrated, medic, preLoading } = useContextValue();
  const { setPreLoadingStatus } = useContextUpdate();

  const fetchPatient = async (queryPatientId: string) => {
    if (session) {
      setPreLoadingStatus(true);
      const patientData = await getPatient(session.accessToken, queryPatientId);
      if (patientData) {
        setPatient(patientData);
        const resultsData = await getPatientResults(session.accessToken, patientId);
        if (resultsData) {
          setAppointmentResults(resultsData.data);
        }
      }
      setPreLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && !medic.active) {
        navigate("/app");
      } else {
        if (patientId) {
          fetchPatient(patientId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rehydrated, medic, session]);

  function Header() {
    return (
      <div className={clsx("md:py-7", "w-full", "flex items-center justify-between", "text-examedi-gray-2")}>
        <div>
          <div className="text-lg md:text-[1.75rem] font-bold">{patient?.fullname}</div>
          <div className="text-xs md:text-xl  mt-[5px]">Ten acceso a los resultados de los exámenes de tu paciente</div>
        </div>
      </div>
    );
  }

  function PreHeader() {
    return <ButtonLink text="Volver" to="/app/telehealth/" icon={<ArrowLeftIcon />} className="link w-fit text-xl" />;
  }

  function Results() {
    return (
      <>
        {!preLoading && appointmentResults?.length === 0 && <p>No existen resultados para este paciente</p>}
        {appointmentResults?.length > 0 && (
          <div className={clsx("flex justify-between", "bg-white", "items-center")}>
            <CollapsableResults items={appointmentResults} />
          </div>
        )}
      </>
    );
  }

  return (
    <DashboardLayout containerClassName="p-5 md:px-10" header={<Header />} preHeader={<PreHeader />}>
      <Results />
    </DashboardLayout>
  );
};

export default PatientResults;
