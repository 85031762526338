import React from "react";

import clsx from "clsx";
import { format } from "date-fns";

import ButtonLink from "../common/inputs/buttons/ButtonLink";

import Collapsible, { CollapsibleHandler } from "~/src/components/common/Collapsible";
import ChevronRightIcon from "~/src/icons/ChevronRightIcon";
import PdfIcon from "~/src/icons/PdfIcon";
import { AppointmentsResult } from "~/src/types/results";
import { parseResultFilename } from "~/src/utils/text";

type CollapsableResultsProps = {
  items: AppointmentsResult[];
  allowMultipleOpened?: boolean;
  collapsedIcon?: React.ReactElement;
  notCollapsedIcon?: React.ReactElement;
  head?: React.ReactElement;
  headCollapsedClassName?: string;
  headNotCollapsedClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
};

function CollapsableResults({
  items,
  allowMultipleOpened,
  collapsedIcon,
  notCollapsedIcon,
  head,
  headCollapsedClassName,
  headNotCollapsedClassName,
  titleClassName,
  bodyClassName,
}: CollapsableResultsProps) {
  return (
    <CollapsibleHandler
      allowMultipleOpened={allowMultipleOpened}
      className={clsx(
        "w-full",
        "rounded-2xl",
        "px-6",
        "border border-examedi-gray-line",
        "divide-y divide-examedi-gray-line",
      )}
    >
      {items.map((item, index) => {
        let date = item.date.split(" ")[0];
        date = format(new Date(date), "dd/MM/yyyy");
        return (
          <Collapsible
            key={index}
            head={
              <>
                {head && React.cloneElement(head, { children: item.title })}
                {!head && (
                  <div className="flex flex-col gap-y-1">
                    <p
                      className={clsx(
                        "text-lg lg:text-base 2lg:text-lg xl:text-xl 2xl:text-2xl",
                        "text-left",
                        "text-examedi-black-dark",
                        "font-medium",
                        titleClassName,
                      )}
                    >
                      {date}
                    </p>
                    <div className="flex gap-x-3 flex-wrap gap-y-3">
                      {item.services.map((service, idx) => (
                        <div
                          key={idx}
                          className={clsx(
                            "bg-examedi-blue-strong-05 rounded-full",
                            "px-4 py-1",
                            "text-examedi-blue-strong",
                          )}
                        >
                          {service}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            }
            className="py-4"
            collapsedIcon={collapsedIcon || <ChevronRightIcon />}
            notCollapsedIcon={notCollapsedIcon || <ChevronRightIcon className="rotate-90" />}
            bodyClassName={bodyClassName}
            headCollapsedClassName={clsx("!p-0", "group", headCollapsedClassName)}
            headNotCollapsedClassName={clsx("!p-0", headNotCollapsedClassName)}
            body={
              <div className="flex flex-col gap-y-4 px-2 py-4 w-full">
                {item.results.map((result, idx) => {
                  const filename = parseResultFilename(result.filename);
                  return (
                    <ButtonLink
                      key={idx}
                      className={clsx("w-full", "flex", "gap-x-2", "whitespace-nowrap", "!justify-start")}
                      text={filename}
                      textClassName="font-medium underline text-examedi-gray-strong truncate"
                      to={result.link}
                      icon={<PdfIcon />}
                    />
                  );
                })}
              </div>
            }
          />
        );
      })}
    </CollapsibleHandler>
  );
}

export default CollapsableResults;
