import { GetPatientPayload, ListPatientsPayload, PatientInvitationPayload } from "../types/patients";
import { GetPrescriptionPayload } from "../types/prescription";
import { PatientResultPayload } from "../types/results";
import { TelehealthPatientsPayload } from "./../types/patients";

import client from "~/src/api-client";
import { Patient } from "~/src/types/models";
import namespaced from "~/src/utils/debug";
const debug = namespaced("api-client/patients");

export async function listPatients(token: string | undefined): Promise<ListPatientsPayload | undefined> {
  try {
    const res = await client.get<ListPatientsPayload>("/medic-referrals/medic/patients/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[listPatients] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug(["listPatients"], err);
    return undefined;
  }
}

export async function listPatientAppointments(token: string | undefined, patientId: string): Promise<any | undefined> {
  try {
    const res = await client.get<any>(`/patients/${patientId}/appointments/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[listPatientAppointments] response was ${res.status}`, res);
    return res?.data.results;
  } catch (err) {
    debug(["listPatientAppointments"], err);
    return undefined;
  }
}

export async function getPatient(token: string | undefined, patientId: string): Promise<GetPatientPayload | undefined> {
  try {
    const res = await client.get<GetPatientPayload>(`/patients/${patientId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getPatient] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug("[getPatient]", err);

    return undefined;
  }
}

export async function getPatientByDocumentNumber(
  token: string | undefined,
  documentNumber: string,
): Promise<GetPatientPayload | undefined> {
  try {
    const res = await client.get<ListPatientsPayload>(`/patients/search/`, {
      params: {
        document_number: documentNumber,
        document_type: "ci",
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getPatientByDocumentNumber] response was ${res.status}`, res);
    return res?.data.data[0];
  } catch (err) {
    debug("[getPatientByDocumentNumber]", err);

    return undefined;
  }
}

export async function getPatientPrescriptions(
  token: string,
  patientId: string,
): Promise<GetPrescriptionPayload | undefined> {
  try {
    const res = await client.get<GetPrescriptionPayload>(`/patients/${patientId}/prescriptions/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getPatientPrescriptions] response was ${res.status}, res`);
    return res.data;
  } catch (err) {
    debug(["getPatientPrescriptions"], err);
    return undefined;
  }
}

export async function sendEmailInvitation(
  token: string,
  invitationPayload: PatientInvitationPayload,
): Promise<PatientInvitationPayload | undefined> {
  try {
    const res = await client.post<PatientInvitationPayload>("/medic-referrals/invite-by-email/", invitationPayload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[sendEmailInvitation] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug(`[sendEmailInvitation]`, err);
    return undefined;
  }
}

export async function listTelehealthPatients(
  token: string | undefined,
): Promise<TelehealthPatientsPayload | undefined> {
  try {
    const res = await client.get<TelehealthPatientsPayload>("/medic-referrals/medic/telehealth-patients/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[listTelehealthPatients] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug(["listTelehealthPatients"], err);
    return undefined;
  }
}

export async function getPatientResults(
  token: string | undefined,
  patientId: string,
): Promise<PatientResultPayload | undefined> {
  try {
    const res = await client.get<any>("/v2/results/list_by_appointment_patient/", {
      headers: { Authorization: `Bearer ${token}` },
      params: { patient: patientId },
    });
    debug(`[getPatientResults] response was ${res.status}`, res);
    return res?.data;
  } catch (err) {
    debug(["getPatientResults"], err);
    return undefined;
  }
}
