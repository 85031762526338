import React from "react";

import clsx from "clsx";

function PdfIcon(props: React.ComponentPropsWithoutRef<"svg"> & { className?: string }) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx("fill-g1", props.className)}
    >
      <path
        d="M14 15.75C14 16.991 12.9536 18 11.6667 18H2.33333C1.04453 18 0 16.991 0 15.75H1.16667C1.16667 16.3723 1.68911 16.875 2.33333 16.875H11.6667C12.312 16.875 12.8333 16.3723 12.8333 15.75H14ZM8.75 6.75C7.78385 6.75 7 5.99414 7 5.0625V1.125H2.33333C1.68911 1.125 1.16667 1.62879 1.16667 2.25V7.875H0V2.25C0 1.00723 1.04453 0 2.33333 0H8.02448C8.4875 0 8.93594 0.177785 9.26406 0.494297L13.4859 4.5668C13.8141 4.8832 14 5.31562 14 5.76211V7.875H12.8333V6.75H8.75ZM12.662 5.36484L8.43646 1.28988C8.36354 1.21711 8.26875 1.16648 8.16667 1.14187V5.0625C8.16667 5.37188 8.42917 5.625 8.75 5.625H12.8151C12.7896 5.52656 12.7385 5.43516 12.662 5.36484ZM3.20833 9C4.3349 9 5.25 9.88242 5.25 10.9688C5.25 12.0551 4.3349 12.9375 3.20833 12.9375H2.91667V14.0625C2.91667 14.3719 2.65562 14.625 2.33333 14.625C2.01104 14.625 1.75 14.3719 1.75 14.0625V9.5625C1.75 9.25312 2.01104 9 2.33333 9H3.20833ZM4.08333 10.9688C4.08333 10.5012 3.69323 10.125 3.20833 10.125H2.91667V11.8125H3.20833C3.69323 11.8125 4.08333 11.4363 4.08333 10.9688ZM5.83333 9.5625C5.83333 9.25312 6.09583 9 6.41667 9H7.29167C8.25781 9 9.04167 9.75586 9.04167 10.6875V12.9375C9.04167 13.8691 8.25781 14.625 7.29167 14.625H6.41667C6.09583 14.625 5.83333 14.3719 5.83333 14.0625V9.5625ZM7 13.5H7.29167C7.6125 13.5 7.875 13.2469 7.875 12.9375V10.6875C7.875 10.3781 7.6125 10.125 7.29167 10.125H7V13.5ZM12.25 9C12.5708 9 12.8333 9.25312 12.8333 9.5625C12.8333 9.87188 12.5708 10.125 12.25 10.125H11.0833V11.25H12.25C12.5708 11.25 12.8333 11.5031 12.8333 11.8125C12.8333 12.1219 12.5708 12.375 12.25 12.375H11.0833V14.0625C11.0833 14.3719 10.8208 14.625 10.5 14.625C10.1792 14.625 9.91667 14.3719 9.91667 14.0625V9.5625C9.91667 9.25312 10.1792 9 10.5 9H12.25Z"
        fill="#7B7B7B"
      />
    </svg>
  );
}

export default PdfIcon;
