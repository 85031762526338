import { User } from "~/src/types/models";

export function capitalizeFirstLetter(text: string): string {
  if (!text) return "";
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function hasNumber(text: string): boolean {
  return /\d/.test(text);
}

export function hasCapital(text: string): boolean {
  return /(?=.*[A-Z])/.test(text);
}

export function hasLower(text: string): boolean {
  return /(?=.*[a-z])/.test(text);
}

export function censure(secret?: string | null) {
  return "*".repeat((secret || "").length) || undefined;
}

export function communeSorter(
  fe: { name: string; center: [number, number] },
  se: { name: string; center: [number, number] },
) {
  const firstComparable = fe.name.startsWith("Ñ") ? fe.name.replace("Ñ", "N") : fe.name;
  const secondComparable = se.name.startsWith("Ñ") ? se.name.replace("Ñ", "N") : se.name;
  return firstComparable > secondComparable ? -1 : 1;
}

export function parseUserEmail(data: User): User {
  return { ...data, email: data.email.trim().toLowerCase() };
}

export function parseResultFilename(filename: string) {
  filename = filename.includes("_examen_") ? filename.split("_examen_")[1] : filename;
  if (filename.length > 100) filename = `${filename.slice(0, 100)}...`;
  return filename;
}
